/** Generated from EConfigurationFeature.java. Do not modify this file! */


export type EConfigurationFeatureEntry = 'CONFIGURE_PROJECTS' | 'CONFIGURE_ANALYSIS_PROFILES' | 'CONFIGURE_METRIC_THRESHOLD_CONFIGURATIONS' | 'CONFIGURE_USERS' | 'CONFIGURE_GROUPS' | 'CONFIGURE_EXTERNAL_CREDENTIALS' | 'CONFIGURE_QUALITY_REPORTS' | 'CONFIGURE_RETROSPECTIVES' | 'CONFIGURE_PROFILERS' | 'CONFIGURE_REPOSITORIES';

export class EConfigurationFeature {
	static readonly CONFIGURE_PROJECTS = new EConfigurationFeature(0, 'CONFIGURE_PROJECTS');
	static readonly CONFIGURE_ANALYSIS_PROFILES = new EConfigurationFeature(1, 'CONFIGURE_ANALYSIS_PROFILES');
	static readonly CONFIGURE_METRIC_THRESHOLD_CONFIGURATIONS = new EConfigurationFeature(2, 'CONFIGURE_METRIC_THRESHOLD_CONFIGURATIONS');
	static readonly CONFIGURE_USERS = new EConfigurationFeature(3, 'CONFIGURE_USERS');
	static readonly CONFIGURE_GROUPS = new EConfigurationFeature(4, 'CONFIGURE_GROUPS');
	static readonly CONFIGURE_EXTERNAL_CREDENTIALS = new EConfigurationFeature(5, 'CONFIGURE_EXTERNAL_CREDENTIALS');
	static readonly CONFIGURE_QUALITY_REPORTS = new EConfigurationFeature(6, 'CONFIGURE_QUALITY_REPORTS');
	static readonly CONFIGURE_RETROSPECTIVES = new EConfigurationFeature(7, 'CONFIGURE_RETROSPECTIVES');
	static readonly CONFIGURE_PROFILERS = new EConfigurationFeature(8, 'CONFIGURE_PROFILERS');
	static readonly CONFIGURE_REPOSITORIES = new EConfigurationFeature(9, 'CONFIGURE_REPOSITORIES');
	static readonly values = [
		EConfigurationFeature.CONFIGURE_PROJECTS,
		EConfigurationFeature.CONFIGURE_ANALYSIS_PROFILES,
		EConfigurationFeature.CONFIGURE_METRIC_THRESHOLD_CONFIGURATIONS,
		EConfigurationFeature.CONFIGURE_USERS,
		EConfigurationFeature.CONFIGURE_GROUPS,
		EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS,
		EConfigurationFeature.CONFIGURE_QUALITY_REPORTS,
		EConfigurationFeature.CONFIGURE_RETROSPECTIVES,
		EConfigurationFeature.CONFIGURE_PROFILERS,
		EConfigurationFeature.CONFIGURE_REPOSITORIES
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EConfigurationFeatureEntry,

	){
	}

	toString() {
		return this.name;
	}
}

