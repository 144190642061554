import { type NotificationData, notifications } from '@mantine/notifications';
import { ServiceCallError } from 'api/ServiceCallError';
import '@mantine/notifications/styles.css';
import type { ReactNode } from 'react';
import { ColorUtils } from 'ts/commons/ColorUtils';
import { Icon } from 'ts/components/Icon';
import './ToastNotification.less';

export { Notifications } from '@mantine/notifications';

const AUTO_HIDE_AFTER_MSECS = 10000;

/** Use this class to display toasts (i.e., short and usually disappearing messages). */
export class ToastNotification {
	private static readonly DEFAULT_OPTIONS = {
		autoClose: AUTO_HIDE_AFTER_MSECS,
		closeButtonProps: { 'aria-label': 'Hide notification' }
	};

	/** Whether the message showing "Teamscale is not reachable" is currently shown on the page. */
	private static serverNotReachableIsShown = false;

	/** Options to set for sticky (=non-vanishing) messages. */
	public static STICKY_TOAST_OPTIONS: Partial<NotificationData> = {
		autoClose: false
	};

	/** Displays a success message. */
	public static success(message: ReactNode): void {
		notifications.show({
			...ToastNotification.DEFAULT_OPTIONS,
			color: ColorUtils.TEAMSCALE_GREEN,
			autoClose: AUTO_HIDE_AFTER_MSECS / 2.0,
			message
		});
	}

	/** Displays an info toast message. */
	public static info(message: ReactNode, additionalOptions?: Partial<NotificationData>): void {
		notifications.show({
			...ToastNotification.DEFAULT_OPTIONS,
			color: ColorUtils.TEAMSCALE_DARK_BLUE,
			message,
			...additionalOptions
		});
	}

	/** Updates an info toast message. */
	public static updateInfo(id: string, message: ReactNode, additionalOptions?: Partial<NotificationData>): void {
		notifications.update({
			...ToastNotification.DEFAULT_OPTIONS,
			color: ColorUtils.TEAMSCALE_DARK_BLUE,
			id,
			message,
			...additionalOptions
		});
	}

	/** Displays a warning message. */
	public static warning(message: ReactNode, additionalOptions?: Partial<NotificationData>): void {
		notifications.show({
			...ToastNotification.DEFAULT_OPTIONS,
			color: ColorUtils.TEAMSCALE_DARK_YELLOW,
			message: (
				<>
					<Icon name="exclamation triangle" style={{ color: ColorUtils.TEAMSCALE_DARK_YELLOW }} />
					{message}
				</>
			),
			...additionalOptions
		});
	}

	/** Displays an error message. */
	public static error(errorOrMessage: ReactNode | Error, additionalMessage?: string): void {
		let message;
		if (errorOrMessage instanceof Error) {
			message = this.appendAdditionalMessage(errorOrMessage.message, additionalMessage);
		} else if (typeof errorOrMessage === 'string') {
			message = this.appendAdditionalMessage(errorOrMessage, additionalMessage);
		} else {
			message = errorOrMessage;
		}
		notifications.show({
			...ToastNotification.DEFAULT_OPTIONS,
			color: ColorUtils.TEAMSCALE_RED,
			message: (
				<>
					<Icon name="close" style={{ color: ColorUtils.TEAMSCALE_RED }} />
					{message}
				</>
			)
		});
	}

	private static appendAdditionalMessage(message: string, additionalMessage?: string): string {
		if (additionalMessage != null) {
			message += ' ' + additionalMessage;
		}
		return message;
	}

	/**
	 * Displays a service call error as a toast message. Timeouts and connection resets are displayed differently than
	 * regular service call errors to match the default error handler behavior. If the given error is not a service call
	 * error it is rethrown. We use this to avoid single request failures to crash the whole page state and therefore
	 * loose all input whenever the connection becomes unstable see (TS-22737).
	 *
	 * @param error An arbitrary error object from a rejected promise.
	 */
	public static showIfServiceError(error: unknown): void {
		ToastNotification.showIfServiceErrorWithAdditionalMessage(error);
	}

	/**
	 * Displays a service call error as a toast message. Timeouts and connection resets are displayed differently than
	 * regular service call errors to match the default error handler behavior. If the given error is not a service call
	 * error it is rethrown. We use this to avoid single request failures to crash the whole page state and therefore
	 * loose all input whenever the connection becomes unstable see (TS-22737).
	 *
	 * @param error An arbitrary error object from a rejected promise.
	 * @param additionalMessage An additional message to append to the error message.
	 */
	public static showIfServiceErrorWithAdditionalMessage(error: unknown, additionalMessage?: string): void {
		if (!(error instanceof ServiceCallError)) {
			throw error;
		}
		if (error.statusCode === 0) {
			// Status 0 indicates that Teamscale server may be down for some unknown reason.
			// We do nothing here, so view content remain shown while
			// the view tells the user about this connection issue. Useful for auto-refreshing pages.
			ToastNotification.showUnreachableServerWarning();
			return;
		}
		ToastNotification.error(error, additionalMessage);
	}

	/** Removes all open toasters immediately. No animation is displayed. */
	public static clearAll(): void {
		notifications.clean();
	}

	/** Displays a warning to the user indicating that Teamscale server is unreachable. */
	public static showUnreachableServerWarning(): void {
		if (!ToastNotification.serverNotReachableIsShown) {
			ToastNotification.serverNotReachableIsShown = true;
			ToastNotification.warning('Teamscale server is unreachable. Maybe network connection is down?', {
				onClose: () => {
					ToastNotification.serverNotReachableIsShown = false;
				}
			});
		}
	}

	/** Hides the toast with the given ID. */
	public static hide(toastId: string) {
		notifications.hide(toastId);
	}
}
