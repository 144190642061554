import clsx from 'clsx';
import { type JSX, useState } from 'react';
import type { DropdownProps } from 'ts/components/Dropdown';
import { Icon } from 'ts/components/Icon';
import { Checkbox } from 'ts/components/Checkbox';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { useUserInfo } from 'ts/base/hooks/UserInfoHook';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { DropdownItemOptions } from 'ts/commons/InMenuSearchableDropdown';
import { addDropdownItemsWithHeader, InMenuSearchableDropdown } from 'ts/commons/InMenuSearchableDropdown';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { UIUtils } from 'ts/commons/UIUtils';
import { useDashboards } from 'ts/perspectives/dashboard/DashboardPerspectiveSettingsBarAddition';
import { EBasicPermission } from 'typedefs/EBasicPermission';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import styles from './DashboardSelector.module.less';

/** Props for DashboardSelector. */
type DashboardSelectorProps = {
	selectorId?: string;
	/** The active/selected dashboard. */
	activeDashboard: UserResolvedDashboardDescriptor | null;
	requirePermission?: EBasicPermission;
};

/** Shows the dashboard selector. */
export function DashboardSelector({
	selectorId,
	activeDashboard,
	requirePermission
}: DashboardSelectorProps): JSX.Element {
	const username = useUserInfo().currentUser.username;
	const [filterText, setFilterText] = useState('');
	const items = useDashboardDropdownItems(username, filterText, activeDashboard, requirePermission);

	return (
		<InMenuSearchableDropdown
			button
			id={selectorId}
			onChange={(event: unknown, data: DropdownProps) => onDashboardSelectionChange(String(data.value))}
			value={selectActiveDashboard(activeDashboard, items)}
			filterQuery={filterText}
			onFilterChange={event => setFilterText(event)}
			className={clsx(
				'auto-menu-width',
				'unobtrusive',
				'floating-width',
				'icon',
				'limit-height-to-screen',
				styles.dashboardDropdown
			)}
			text={getSelectedDashboardLabel(activeDashboard)}
			icon="angle down"
			items={items}
		/>
	);
}

/**
 * Returns the active dashboard value or an empty string if there is no active dashboard. It also handles the selected
 * props of the dashboard dropdown items.
 */
function selectActiveDashboard(
	activeDashboard: UserResolvedDashboardDescriptor | null,
	items: DropdownItemOptions[]
): string {
	if (!activeDashboard) {
		return '';
	}
	const activeValue = activeDashboard.id!;
	setCurrentlySelectedDashboardItem(activeValue, items);
	return activeValue;
}

/**
 * Handles the selected item props of the dropdown. If the currently selected dashboard isn't visible (in the dashboard)
 * anymore, the first item in the dashboard gets selected automatically.
 */
function setCurrentlySelectedDashboardItem(activeDashboardValue: string, items: DropdownItemOptions[]) {
	const filteredItems = items.filter(item => item.value);

	if (filteredItems.length > 0 && !filteredItems.find(item => item.value === activeDashboardValue)) {
		filteredItems[0]!.selected = true;
	}
}

/** Returns the text to show on the collapsed dashboard selector dropdown. */
function getSelectedDashboardLabel(activeDashboard: UserResolvedDashboardDescriptor | null) {
	if (!activeDashboard) {
		return 'Dashboards...';
	}
	if (!activeDashboard.hasOwnProperty('group') || !activeDashboard.group || activeDashboard.group.length === 0) {
		return activeDashboard.name;
	}
	return activeDashboard.group + '/' + activeDashboard.name;
}

function useOnlyMyDashboardsState(activeDashboard: UserResolvedDashboardDescriptor | null, username: string) {
	const showOnlyMyDashboardsKey = 'dashboard-view-show-only-mine';
	let initialOnlyMyDashboards = UIUtils.getLocalStorage().get(showOnlyMyDashboardsKey);
	if (initialOnlyMyDashboards && activeDashboard?.owner !== username) {
		// Automatically switches the 'only my dashboards' checkbox to show all if the user directly navigates to a
		// dashboard that would not be visible when the switch is turned on.
		initialOnlyMyDashboards = false;
	}
	const [myDashboards, setMyDashboardsState] = useState(initialOnlyMyDashboards);
	const setMyDashboards = (myDashboards: boolean) => {
		setMyDashboardsState(myDashboards);
		UIUtils.getLocalStorage().set(showOnlyMyDashboardsKey, myDashboards);
	};
	return [myDashboards, setMyDashboards];
}

/** Determines the dropdown items that should be shown already taking into account filtering. */
function useDashboardDropdownItems(
	username: string,
	filterText: string,
	activeDashboard: UserResolvedDashboardDescriptor | null,
	requirePermission?: EBasicPermission
): DropdownItemOptions[] {
	let dashboards = useDashboards();
	const [myDashboards, setMyDashboards] = useOnlyMyDashboardsState(activeDashboard, username);
	if (myDashboards) {
		dashboards = dashboards.filter(dashboard => dashboard.isMine);
	}
	const items: DropdownItemOptions[] = [];
	const toDashboardItem = (dashboard: UserResolvedDashboardDescriptor) =>
		buildDashboardItem(dashboard, requirePermission);
	addDashboardsToItems(dashboards, items, filterText, toDashboardItem);

	function myDashboardToggle() {
		return (
			<ul>
				<li>
					<OnlyMyDashboardsCheckbox myDashboards={myDashboards} setMyDashboards={setMyDashboards} />
				</li>
				<li>
					<label>Only my dashboards</label>
				</li>
			</ul>
		);
	}

	items.unshift({
		as: myDashboardToggle,
		disabled: true,
		value: myDashboards,
		key: 'my-dashboards'
	});
	return items;
}

/** Adds the dashboards to the items array already taking into account grouping & filtering */
function addDashboardsToItems(
	dashboards: UserResolvedDashboardDescriptor[],
	items: DropdownItemOptions[],
	filterText: string,
	toDashboardItem: (dashboard: UserResolvedDashboardDescriptor) => DropdownItemOptions
) {
	const defaultCategoryName = 'Others';

	const dashboardsByGroup = ArrayUtils.groupBy(
		dashboards.sort((a, b) => StringUtils.compareCaseInsensitive(getDashboardGroup(a), getDashboardGroup(b))),
		getDashboardGroup
	);

	addFavoritesToItems(items, filterText, dashboards, toDashboardItem);
	dashboardsByGroup.forEach((dashboard, group) => {
		if (group === defaultCategoryName) {
			return;
		}
		addDropdownItemsWithHeader(
			items,
			group,
			dashboard
				.filter(dashboard => !dashboard.isFavorite)
				.filter(dashboard => filterDashboardsByText(dashboard, filterText))
				.map(toDashboardItem),
			'folder'
		);
	});

	if (dashboardsByGroup.has(defaultCategoryName)) {
		addGroupOthersToItems(items, filterText, dashboardsByGroup, toDashboardItem);
	}
}

function addFavoritesToItems(
	items: DropdownItemOptions[],
	filterText: string,
	dashboards: UserResolvedDashboardDescriptor[],
	toDashboardItem: (dashboard: UserResolvedDashboardDescriptor) => DropdownItemOptions
): void {
	addDropdownItemsWithHeader(
		items,
		'Favorites',
		dashboards
			.filter(dashboard => dashboard.isFavorite)
			.filter(dashboard => filterDashboardsByText(dashboard, filterText))
			.sort((a, b) => StringUtils.compareCaseInsensitive(a.name, b.name))
			.map(toDashboardItem),
		<Icon name="star" color="yellow" />
	);
}

function addGroupOthersToItems(
	items: DropdownItemOptions[],
	filterText: string,
	dashboardsByGroup: Map<string, UserResolvedDashboardDescriptor[]>,
	toDashboardItem: (dashboard: UserResolvedDashboardDescriptor) => DropdownItemOptions
): void {
	const header = dashboardsByGroup.size === 1 ? 'Dashboards' : 'Others';
	addDropdownItemsWithHeader(
		items,
		header,
		dashboardsByGroup
			.get('Others')!
			.filter(dashboard => !dashboard.isFavorite)
			.filter(dashboard => filterDashboardsByText(dashboard, filterText))
			.map(toDashboardItem),
		'folder'
	);
}

function getDashboardGroup(dashboard: UserResolvedDashboardDescriptor): string {
	if (!dashboard.hasOwnProperty('group') || !dashboard.group) {
		return 'Others';
	}
	return dashboard.group.toLowerCase();
}

function buildDashboardItem(
	dashboard: UserResolvedDashboardDescriptor,
	requirePermission?: EBasicPermission
): DropdownItemOptions {
	let key = dashboard.id;
	let description = '';
	if (dashboard.isFavorite) {
		key = 'FAV-' + key;
		if (dashboard.hasOwnProperty('group') && dashboard.group) {
			description += dashboard.group;
		} else {
			description += 'Others';
		}
	}
	return {
		text: dashboard.name,
		value: dashboard.id,
		as: DashboardLink,
		key,
		description,
		disabled: !shouldBeEnabled(dashboard, requirePermission)
	};
}

function shouldBeEnabled(dashboard: UserResolvedDashboardDescriptor, requirePermission: EBasicPermission | undefined) {
	if (requirePermission === EBasicPermission.EDIT_ROLES) {
		return dashboard.canEditRoles;
	} else if (requirePermission === EBasicPermission.EDIT) {
		return dashboard.canWrite;
	}
	return true;
}

/** Props for DashboardLink. */
type DashboardLinkProps = { 'data-value': string } & Record<string, unknown>;

function dashboardLink(hash: NavigationHash, dashboardId: string) {
	hash.setId(dashboardId);
	return hash.toString();
}

/** Links to the given project while keeping the current perspective and view. */
function DashboardLink({ 'data-value': dashboardId, ...props }: DashboardLinkProps) {
	const hash = useNavigationHash();
	return <TeamscaleLink to={dashboardLink(hash, dashboardId)} {...props} />;
}

function filterDashboardsByText(dashboard: UserResolvedDashboardDescriptor, filterText: string): boolean {
	let textToFilter = dashboard.name;
	if (dashboard.hasOwnProperty('group') && dashboard.group) {
		textToFilter += dashboard.group;
	}
	return StringUtils.isContainedInAnyIgnoreCase(filterText, textToFilter);
}

/** Callback for updating the current dashboard value */
function onDashboardSelectionChange(dashboardId: string): void {
	const hash = NavigationHash.getCurrent();
	NavigationUtils.updateLocation(dashboardLink(hash, dashboardId));
}

function OnlyMyDashboardsCheckbox({
	myDashboards,
	setMyDashboards
}: {
	myDashboards: boolean;
	setMyDashboards: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	return (
		<Checkbox
			toggle
			checked={myDashboards}
			value="onlyMyDashboardToggle"
			onMouseDown={event => {
				setMyDashboards(!myDashboards);
				event.stopPropagation();
				event.preventDefault();
			}}
		/>
	);
}
