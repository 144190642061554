import type { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ActivityPerspectiveDescriptor } from 'ts/perspectives/activity/ActivityPerspectiveDescriptor';
import { AdminPerspectiveDescriptor } from 'ts/perspectives/admin/AdminPerspectiveDescriptor';
import { ApiPerspectiveDescriptor } from 'ts/perspectives/api/ApiPerspectiveDescriptor';
import { ArchitecturePerspectiveDescriptor } from 'ts/perspectives/architecture/ArchitecturePerspectiveDescriptor';
import { AuditPerspectiveDescriptor } from 'ts/perspectives/audit/AuditPerspectiveDescriptor';
import { ChecksPerspectiveDescriptor } from 'ts/perspectives/checks/ChecksPerspectiveDescriptor';
import { ComparePerspectiveDescriptor } from 'ts/perspectives/compare/ComparePerspectiveDescriptor';
import { DashboardPerspectiveDescriptor } from 'ts/perspectives/dashboard/DashboardPerspectiveDescriptor';
import { DeltaPerspectiveDescriptor } from 'ts/perspectives/delta/DeltaPerspectiveDescriptor';
import { FindingsPerspectiveDescriptor } from 'ts/perspectives/findings/FindingsPerspectiveDescriptor';
import { MetricsPerspectiveDescriptor } from 'ts/perspectives/metrics/MetricsPerspectiveDescriptor';
import { ModelComparePerspectiveDescriptor } from 'ts/perspectives/model_compare/ModelComparePerspectiveDescriptor';
import { ProjectPerspectiveDescriptor } from 'ts/perspectives/project/ProjectPerspectiveDescriptor';
import { QualityControlPerspectiveDescriptor } from 'ts/perspectives/quality_control/QualityControlPerspectiveDescriptor';
import { RepositoryPerspectiveDescriptor } from 'ts/perspectives/repository/RepositoryPerspectiveDescriptor';
import { RequirementsTracingPerspectiveDescriptor } from 'ts/perspectives/requirement_tracing/RequirementsTracingPerspectiveDescriptor';
import { SearchPerspectiveDescriptor } from 'ts/perspectives/search/SearchPerspectiveDescriptor';
import { SnippetAnalysisPerspectiveDescriptor } from 'ts/perspectives/snippet_anaylsis/SnippetAnalysisPerspectiveDescriptor';
import { SystemPerspectiveDescriptor } from 'ts/perspectives/system/SystemPerspectiveDescriptor';
import { TestGapsPerspectiveDescriptor } from 'ts/perspectives/tests/TestGapsPerspectiveDescriptor';
import { UserPerspectiveDescriptor } from 'ts/perspectives/user/UserPerspectiveDescriptor';

/** Lists all Teamscale perspectives. */
export const PERSPECTIVES: PerspectiveViewDescriptorBase[] = [
	new DashboardPerspectiveDescriptor(),
	new ActivityPerspectiveDescriptor(),
	new FindingsPerspectiveDescriptor(),
	new MetricsPerspectiveDescriptor(),
	new AuditPerspectiveDescriptor(),
	new ComparePerspectiveDescriptor(),
	new ModelComparePerspectiveDescriptor(),
	new ArchitecturePerspectiveDescriptor(),
	new TestGapsPerspectiveDescriptor(),
	new DeltaPerspectiveDescriptor(),
	new QualityControlPerspectiveDescriptor(),
	new SystemPerspectiveDescriptor(),
	new AdminPerspectiveDescriptor(),
	new SearchPerspectiveDescriptor(),
	new UserPerspectiveDescriptor(),
	new ApiPerspectiveDescriptor(),
	new ChecksPerspectiveDescriptor(),
	new RequirementsTracingPerspectiveDescriptor(),
	new ProjectPerspectiveDescriptor(),
	new RepositoryPerspectiveDescriptor(),
	new SnippetAnalysisPerspectiveDescriptor()
];
