import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { DeltaParameters } from 'ts/perspectives/delta/commons/DeltaParameters';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';

/** Collection of all delta subview descriptors. */
export const EDeltaView = {
	PARAMETER: {
		name: 'Delta Parameters',
		anchor: 'input',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./parameters/ParameterDeltaView'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	FILE: {
		name: 'Changed Files',
		anchor: 'files',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./files/FileDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'files'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	REPOSITORY: {
		name: 'Repository Changes',
		anchor: 'changes',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./repository/RepositoryDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'changes'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	CHANGE_TREEMAP: {
		name: 'Change Treemap',
		anchor: 'treemap',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./change_treemap/ChangeTreemapDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'treemap'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	TEST_GAP_TREEMAP: {
		name: 'Test Gap Treemap',
		anchor: 'test-gap',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./test_gaps/TestGapDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'test-gap'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	METRICS: {
		name: 'Metric Changes',
		anchor: 'metrics',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./metrics/MetricsDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'metrics'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	FINDING: {
		name: 'Findings Churn',
		anchor: 'findings',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./findings/FindingDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'findings'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor,
	TESTS: {
		name: 'Impacted Tests',
		anchor: 'tests',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./impacted_tests/ImpactedTestsDeltaView'),
		canBeAccessed: (perspectiveContext: PerspectiveContext, hash: NavigationHash) =>
			canShowDeltaViews(perspectiveContext, hash, 'tests'),
		keepPathAndArgumentsOfCurrentViewForSubviews: true
	} as ViewDescriptor
} as const;

/** Returns whether subviews should be shown for the delta perspective. */
function canShowDeltaViews(perspectiveContext: PerspectiveContext, hash: NavigationHash, view: string): boolean {
	if (hash.getBoolean(DeltaParameters.IS_SPEC_ITEM_DELTA_PARAMETER_NAME) && isExcludedForSpecItemDelta(view)) {
		return false;
	}
	if (
		!StringUtils.isEmptyOrWhitespace(hash.getString(DeltaParameters.GROUP_PARAMETER_NAME)) &&
		isExcludedForGroupDelta(view)
	) {
		return false;
	}
	if (hash.getBoolean(DeltaParameters.SHOW_MERGE_REQUEST_PARAMETER_NAME) && isExcludedForMergeDelta(view)) {
		return false;
	}

	return hash.getCommitParameter(DeltaParameters.START_COMMIT_PARAMETER_NAME) !== null;
}

/**
 * Returns true if the given view should be excluded in the delta perspective for spec items. The excluded views are
 * only applicable to code deltas, but not to spec item deltas.
 */
function isExcludedForSpecItemDelta(view: string): boolean {
	const excludedViewsForSpecItems = [EDeltaView.TEST_GAP_TREEMAP.anchor, EDeltaView.TESTS.anchor];
	return excludedViewsForSpecItems.includes(view);
}

/** Returns true if the given view should be excluded in the delta perspective when inspecting a merge delta. */
function isExcludedForMergeDelta(view: string): boolean {
	const excludedViewsForSpecItems = [EDeltaView.METRICS.anchor, EDeltaView.CHANGE_TREEMAP.anchor];
	return excludedViewsForSpecItems.includes(view);
}

/** Returns true if the given view should be excluded in the delta perspective when a team/group of users is selected. */
function isExcludedForGroupDelta(view: string): boolean {
	const enabledViewsForGroups = [EDeltaView.REPOSITORY.anchor, EDeltaView.FINDING.anchor];
	return !enabledViewsForGroups.includes(view);
}
