import { QUERY } from 'api/Query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ReactUtils } from 'ts/base/ReactUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { DashboardUtils } from 'ts/perspectives/dashboard/DashboardUtils';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';
import { setDashboardPerspectiveProject } from 'ts/perspectives/dashboard/EditDashboardDropdown';
import {
	DEFAULT_DASHBOARD_NAME,
	DEFAULT_DASHBOARD_TEMPLATE_DESCRIPTION,
	DEFAULT_DASHBOARD_TEMPLATE_NAME,
	ModalMode
} from 'ts/perspectives/dashboard/sidebar/Constants';
import type { DashboardViewStateStore } from 'ts/perspectives/dashboard/sidebar/DashboardSidebarViewBase';
import type { DashboardDescriptorWrapper } from 'ts/perspectives/dashboard/widgets/DashboardDescriptorWrapper';
import type { WidgetFactory } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';

/** Categorize widget buttons based on their appearance or artifact */
export function useCategorizeBy<T extends string>(
	widgetIds: string[],
	widgetFactory: WidgetFactory,
	getCategory: (widgetId: string) => string
): Record<T, string[]> {
	return useMemo(
		() =>
			widgetIds.reduce(
				(sortedWidgetIds, widgetId) => {
					const category = getCategory(widgetId);
					const categoryList = sortedWidgetIds[category] ?? [];
					return {
						...sortedWidgetIds,
						[category]: [...categoryList, widgetId]
					};
				},
				{} as Record<T | string, string[]>
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
}

/** Saving modal form values */
export type SaveModalFormValues = {
	/** The name of the dashboard */
	name: string;
	/** The group name of the dashboard */
	group?: string;
	/** Comment related to the dashboard */
	comment?: string;
	/** Description related to the dashboard template */
	description?: string;
};

/** Provides form methods for saving modal */
export function useSaveModalForm(
	modalMode: ModalMode,
	dashboardDescriptor: UserResolvedDashboardDescriptor | DashboardTemplateDescriptor
) {
	return useForm<SaveModalFormValues>({
		defaultValues: getFormDefaultValues(modalMode, dashboardDescriptor)
	});
}

function getFormDefaultValues(modalMode: ModalMode, dashboardDescriptor: SaveModalFormValues): SaveModalFormValues {
	if (modalMode === ModalMode.SaveTemplate) {
		return {
			name: DEFAULT_DASHBOARD_TEMPLATE_NAME,
			comment: DEFAULT_DASHBOARD_TEMPLATE_DESCRIPTION
		};
	}

	return {
		name: StringUtils.isEmptyOrWhitespace(dashboardDescriptor.name)
			? DEFAULT_DASHBOARD_NAME
			: dashboardDescriptor.name,
		group: dashboardDescriptor.group,
		// 'description' field is for dashboard templates
		comment: 'description' in dashboardDescriptor ? dashboardDescriptor.description : dashboardDescriptor.comment
	};
}

/** Create or edit a dashboard */
export async function modifyDashboard(
	formValues: SaveModalFormValues,
	dashboardDescriptor: DashboardDescriptor,
	dashboardDescriptorWrapper: DashboardDescriptorWrapper | null,
	dashboardViewStateStore: DashboardViewStateStore,
	modalMode: ModalMode,
	commit: UnresolvedCommitDescriptor
) {
	const updatedDashboardDescriptor = {
		...dashboardDescriptor,
		...formValues,
		descriptor: dashboardDescriptorWrapper!.obtainUpdatedDescriptor()
	};
	if (modalMode === ModalMode.SaveAs || updatedDashboardDescriptor.id === undefined) {
		// Either we are creating a new dashboard or "saving as" an existing dashboard
		const dashboardId = await QUERY.createDashboard({ ...updatedDashboardDescriptor, id: undefined }).fetch();

		await onSuccessDashboardModification(dashboardId, updatedDashboardDescriptor, dashboardViewStateStore, commit);
		return;
	}

	await QUERY.editDashboard(updatedDashboardDescriptor.id, updatedDashboardDescriptor).fetch();

	await onSuccessDashboardModification(
		updatedDashboardDescriptor.id,
		updatedDashboardDescriptor,
		dashboardViewStateStore,
		commit
	);
}

async function onSuccessDashboardModification(
	dashboardId: string,
	dashboardDescriptor: DashboardDescriptor,
	dashboardViewStateStore: DashboardViewStateStore,
	commit: UnresolvedCommitDescriptor
) {
	dashboardViewStateStore.setState({ hasChanges: false });
	await ReactUtils.queryClient.invalidateQueries();
	const projects = DashboardUtils.getReferencedProjects(dashboardDescriptor);
	const hash = NavigationHash.getCurrent();
	hash.setViewName(EDashboardPerspectiveView.DASHBOARD_SHOW.anchor);
	hash.setId(dashboardId);
	hash.setCommit(commit);
	setDashboardPerspectiveProject(projects, hash);
	hash.reload();
}
