import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the compare perspective */
export const EComparePerspectiveView = {
	CODE_COMPARE: {
		name: 'Code Compare',
		anchor: 'code',
		requiresProject: true,
		projectIsFixed: true,
		hasSmallPadding: true,
		viewCreator: () => import('./CodeCompareView')
	} as ViewDescriptor,
	TEST_COVERAGE_COMPARE: {
		name: 'Test Coverage Compare',
		anchor: 'coverage',
		requiresProject: true,
		projectIsFixed: true,
		hasSmallPadding: true,
		view: () => import('./coverage/TestCoverageCompareView')
	} as ViewDescriptor,
	SPEC_ITEM_COMPARE: {
		name: 'Spec Item Compare',
		anchor: 'spec-item-compare',
		requiresProject: true,
		projectIsFixed: true,
		hasSmallPadding: true,
		viewCreator: () => import('./SpecItemCompareView')
	} as ViewDescriptor
};
