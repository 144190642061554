import { QUERY } from 'api/Query';
import { type JSX, useState } from 'react';
import { Button, ButtonGroup } from 'ts/components/Button';
import { Icon } from 'ts/components/Icon';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { DashboardSelector } from 'ts/base/scaffolding/DashboardSelector';
import { PerspectiveUtils } from 'ts/commons/PerspectiveUtils';
import { DashboardAddDropdown } from 'ts/perspectives/dashboard/DashboardAddDropdown';
import { DashboardFavoriteIcon } from 'ts/perspectives/dashboard/DashboardFavoriteIcon';
import { DashboardUtils } from 'ts/perspectives/dashboard/DashboardUtils';
import { EditDashboardDropdown } from 'ts/perspectives/dashboard/EditDashboardDropdown';
import type { EBasicPermission } from 'typedefs/EBasicPermission';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import styles from './DashboardPerspectiveSettingsBarAddition.module.less';

function NavigationBackSlash(): JSX.Element | null {
	return <div className={styles.dropdownSlash}>/</div>;
}

/**
 * Loads all dashboards that contain data from the currently selected project. If "All projects" is selected in the
 * NavigationHash (='') all dashboards are loaded.
 */
export function useDashboards() {
	const hash = useNavigationHash();
	const project = hash.getProject();
	return QUERY.getAllDashboards({ project }).useSuspendingQuery();
}

type DashboardPerspectiveSettingsBarAdditionProps = {
	showEditMenu?: boolean;
	requirePermission?: EBasicPermission;
};

function useCurrentDashboard(dashboards: UserResolvedDashboardDescriptor[]): UserResolvedDashboardDescriptor | null {
	const navigationHash = useNavigationHash();
	const dashboardId = navigationHash.getId();
	if (dashboardId) {
		return DashboardUtils.findDashboardById(dashboards, dashboardId);
	}
	return null;
}

/**
 * Renders the perspective settings bar section that is appended in the dashboard view. This includes the dashboard
 * selector, an edit menu for the currently selected dashboard and an add menu to create new dashboards.
 */
export function DashboardPerspectiveSettingsBarAddition({
	showEditMenu,
	requirePermission
}: DashboardPerspectiveSettingsBarAdditionProps): JSX.Element {
	const dashboards = useDashboards();
	const initialDashboard = useCurrentDashboard(dashboards);
	return (
		<DashboardSettingsBar
			key={initialDashboard?.id}
			showEditMenu={showEditMenu}
			initialDashboard={initialDashboard}
			requirePermission={requirePermission}
		/>
	);
}

/** Props for the {@link DashboardSettingsBar}. */
type DashboardDescriptorWrapperProps = DashboardPerspectiveSettingsBarAdditionProps & {
	initialDashboard: UserResolvedDashboardDescriptor | null;
	requirePermission?: EBasicPermission;
};

function DashboardSettingsBar({
	showEditMenu,
	initialDashboard,
	requirePermission
}: DashboardDescriptorWrapperProps): JSX.Element {
	const navigationHash = useNavigationHash();
	const [currentDashboard, setDashboard] = useState(initialDashboard);

	if (currentDashboard === null) {
		return <StandaloneDashboardAddDropdownButton />;
	}

	return (
		<>
			<NavigationBackSlash />
			<DashboardFavoriteIcon dashboard={currentDashboard} setDashboard={setDashboard} />
			<DashboardSelector
				selectorId="dashboard-selector"
				activeDashboard={currentDashboard}
				requirePermission={requirePermission}
			/>
			<ButtonGroup primary className={styles.optionDropdownSize}>
				{showEditMenu ? (
					<EditDashboardDropdown dashboard={currentDashboard} setDashboard={setDashboard} />
				) : null}
				<DashboardAddDropdown />
				<Button
					title="Enter fullscreen mode"
					icon={<Icon name="expand" />}
					onClick={() => {
						navigationHash.set(PerspectiveUtils.KIOSK_MODE_PARAMETER, true);
						navigationHash.navigate();
					}}
				/>
			</ButtonGroup>
		</>
	);
}

/** The Add dashboard dropdown button without edit or fullscreen buttons next to it. */
export function StandaloneDashboardAddDropdownButton() {
	return (
		<ButtonGroup primary className={styles.optionDropdownSize}>
			<DashboardAddDropdown />
		</ButtonGroup>
	);
}
