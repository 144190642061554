import { useCallback, useEffect } from 'react';
import { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import { useEventListener } from 'ts/commons/hooks/useEventListener';

/** Provides a keyboard shortcut registry reference. */
export function useKeyboardShortcutRegistry(): void {
	const listener = useCallback((event: KeyboardEvent) => KeyboardShortcutRegistry.INSTANCE.handleKeyDown(event), []);
	useEventListener('keydown', listener);
}

/** Registers a keyboard shortcut while the current component is mounted. */
export function useKeyboardShortcut(
	shortcut: string,
	description: string,
	callback: () => void,
	enabled = true,
	preventDefault = true
): void {
	useEffect(() => {
		if (enabled) {
			KeyboardShortcutRegistry.INSTANCE.registerShortcut(shortcut, description, callback, preventDefault);
		}
	}, [callback, description, preventDefault, shortcut, enabled]);
}
