import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

export const EUserPerspectiveView = {
	MY_PROFILE: {
		name: 'My Profile',
		anchor: 'show',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./UserDetailView')
	} as ViewDescriptor,
	EDIT_PROFILE: {
		name: 'Edit Profile',
		anchor: 'edit',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./user_edit_view/UserEditView')
	} as ViewDescriptor,
	CHANGE_PASSWORD: {
		name: 'Change Password',
		anchor: 'changepassword',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./UserPasswordChangeView'),
		canBeAccessed(context) {
			return context.userInfo.canChangePassword;
		}
	} as ViewDescriptor,
	NOTIFICATIONS: {
		name: 'Preferences',
		anchor: 'preferences',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./UserPreferencesView')
	} as ViewDescriptor,
	IDE_ACCESS_KEY: {
		name: 'Access Keys',
		anchor: 'access-key',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./UserAccessKeyView')
	} as ViewDescriptor
} as const;
