import * as ActivityLinks from './ActivityLinks';
import * as AdminLinks from './AdminLinks';
import * as ApiLinks from './ApiLinks';
import * as ArchitectureLinks from './ArchitectureLinks';
import * as ChecksLinks from './ChecksLinks';
import * as CompareLinks from './CompareLinks';
import * as DashboardLinks from './DashboardLinks';
import * as DeltaLinks from './DeltaLinks';
import * as FindingsLinks from './FindingsLinks';
import * as MetricsLinks from './MetricsLinks';
import * as ModelCompareLinks from './ModelCompareLinks';
import * as ProjectLinks from './ProjectLinks';
import * as QualityControlLinks from './QualityControlLinks';
import * as RepositoryLinks from './RepositoryLinks';
import * as RequirementsTracingLinks from './RequirementsTracingLinks';
import * as SearchLinks from './SearchLinks';
import * as SystemLinks from './SystemLinks';
import * as TestGapsLinks from './TestGapsLinks';
import * as UserLinks from './UserLinks';

/**
 * Provides links to all kinds of views within Teamscale.
 *
 * We group all the creation functions together so that 1. it can easily be imported (i.e., IntelliJ can suggest the
 * import) 2. You don't have to know which perspective view currently belongs to 3. We can still keep the files with the
 * actual link functions small enough to end up with a huge god class
 */
export const Links = {
	...ActivityLinks,
	...AdminLinks,
	...ApiLinks,
	...ArchitectureLinks,
	...ChecksLinks,
	...CompareLinks,
	...DashboardLinks,
	...DeltaLinks,
	...FindingsLinks,
	...MetricsLinks,
	...ModelCompareLinks,
	...ProjectLinks,
	...QualityControlLinks,
	...RepositoryLinks,
	...RequirementsTracingLinks,
	...SearchLinks,
	...SystemLinks,
	...TestGapsLinks,
	...UserLinks
};
