import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the Snippet Analysis perspective */
export const ESnippetAnalysisPerspectiveView = {
	/** The snippet analysis view. */
	SNIPPET_ANALYSIS: {
		name: 'Snippet Analysis',
		anchor: 'snippet-analysis',
		requiresProject: true,
		viewCreator: () => import('./SnippetAnalysisView')
	} as ViewDescriptor
};
