import { QUERY } from 'api/Query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as PreprocessorExpansionTemplate from 'soy/commons/formatter/PreprocessorExpressionTemplate.soy.generated';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as style from 'ts-closure-library/lib/style/style';
import * as soy from 'ts/base/soy/SoyRenderer';
import { SourceFormatterContext } from 'ts/commons/formatter/SourceFormatterContext';
import { SourceFormatterUtils } from 'ts/commons/formatter/SourceFormatterUtils';
import type { StyleRegion } from 'ts/commons/formatter/StyleRegion';
import type { TokenStyleCssCache } from 'ts/commons/formatter/TokenStyleCssCache';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import { SourceLineFormatter } from 'ts/perspectives/metrics/code/SourceLineFormatter';
import type { SinglePreprocessorExpansionTransport } from 'typedefs/SinglePreprocessorExpansionTransport';

/**
 * Handles the addition of preprocessor expansions to a code element (an HTML element representing an entire file).
 *
 * This adds hover highlighting to each expansion group (i.e., tokens that are expanded together) and buttons for
 * expansion of a group (i.e., replacement with the preprocessing result) and revert of the expansion (show original
 * code again).
 */
export class PreprocessorExpansionHandler {
	/*
	 * Name of the css class used for highlighting an expansion group on hover.
	 */
	private static readonly EXPANSION_GROUP_HIGHLIGHT_CLASS = 'preprocessor-expansion-group-highlighted';

	/*
	 * Name of the css class used for highlighting an expanded expansion group.
	 */
	private static readonly EXPANSION_GROUP_EXPANDED_CLASS = 'preprocessor-expansion-group-expanded';

	private readonly project: string;
	private readonly uniformPath: string;
	private readonly tokenStyleCssCache: TokenStyleCssCache;
	private readonly formatterContext: SourceFormatterContext;

	public constructor(
		project: string,
		uniformPath: string,
		tokenStyleCssCache: TokenStyleCssCache,
		sourceFormatterContext: SourceFormatterContext
	) {
		this.project = project;
		this.uniformPath = uniformPath;
		this.tokenStyleCssCache = tokenStyleCssCache;
		this.formatterContext = sourceFormatterContext;
	}

	/**
	 * Generates from the given key-value map, a map from value to associated keys. In this case, we use it to determine
	 * which character offsets are expanded by a given expansion group. Example: [1->g1, 2->g1, 3->g2] will yield
	 * [g1->[1,2], g2->[3]].
	 */
	private static mapExpansionGroupToExpandedOffsets(
		offsetToExpansionGroup: Record<number, number>
	): Map<number, number[]> {
		const expansionGroupToExpandedOffsets = new Map<number, number[]>();
		for (const charOffsetStr in offsetToExpansionGroup) {
			const charOffset = parseInt(charOffsetStr);
			const expansionGroup = offsetToExpansionGroup[charOffset]!;
			const groupOffsets = expansionGroupToExpandedOffsets.get(expansionGroup);
			if (groupOffsets != null) {
				groupOffsets.push(charOffset);
			} else {
				expansionGroupToExpandedOffsets.set(expansionGroup, [charOffset]);
			}
		}
		return expansionGroupToExpandedOffsets;
	}

	/**
	 * Creates a new button with the given id, text, and title and inserts it at the given position in/after the given
	 * htmlElement.
	 *
	 * @param expansionButtonId Id of the button that will be created here
	 * @param buttonText Text of the button that will be created here
	 * @param buttonTitle Title of the button that will be created here
	 * @param tokenHtmlElement Element that will be parent of the wrapper span (which contains the button)
	 * @param position Position of the created wrapper in the given parent element
	 * @param overlay Whether the new button should be shown over other text or push other text to the right
	 */
	private static createAndInsertPreprocessorExpansionButton(
		expansionButtonId: string,
		buttonText: string,
		buttonTitle: string,
		tokenHtmlElement: Element,
		position: InsertPosition,
		overlay = false
	): HTMLElement {
		let wrapperDisplayStyle = 'inline';
		if (overlay) {
			wrapperDisplayStyle = 'inline-flex';
		}
		const buttonWrapper = soy.renderAsElement(PreprocessorExpansionTemplate.preprocessorExpansionButtonWrapper, {
			buttonTitle,
			buttonText,
			expansionButtonId,
			wrapperDisplayStyle
		});
		if (overlay) {
			style.setWidth(buttonWrapper, 0);
		} else {
			// If width is not 0, the surrounding highlight box will include the button. This ensures that the box does
			// not extend to the right of the button.
			style.setStyle(buttonWrapper.firstElementChild, 'margin', 0);
		}
		tokenHtmlElement.insertAdjacentElement(position, buttonWrapper);
		return buttonWrapper.firstElementChild as HTMLElement;
	}

	/** Returns the id of the "apply preprocessor expansion" button for the given expansion group. */
	private static createExpansionButtonId(expansionGroup: number): string {
		return 'button-expand-preprocessor-' + expansionGroup;
	}

	/** Adds a button to (visually) revert the preprocessor expansion to the given expansion element. */
	private static addRevertExpansionButton(
		expansionGroup: number,
		expansionHtmlElement: HTMLElement,
		fileIndex: number,
		codeElement: Element,
		expansion: SinglePreprocessorExpansionTransport
	): void {
		const revertExpansionButton = PreprocessorExpansionHandler.createAndInsertPreprocessorExpansionButton(
			'button-revert-preprocessor-' + expansionGroup,
			'-',
			'revert preprocessor expansion',
			expansionHtmlElement,
			'beforeend'
		);
		revertExpansionButton.classList.toggle(PreprocessorExpansionHandler.EXPANSION_GROUP_EXPANDED_CLASS, true);
		const revertExpansionAction = UIUtils.preventDefaultEventAction(() => {
			tsdom.removeNodes([revertExpansionButton]);
			PreprocessorExpansionHandler.onRevertExpansionGroupClicked(
				fileIndex,
				codeElement,
				expansionHtmlElement,
				expansion.offsetsOfReplacedOriginalTokens
			);
		});
		events.listen(revertExpansionButton, EventType.CLICK, revertExpansionAction);
	}

	/**
	 * Reverts the expansion of the given expansion group (shows the original, unexpanded code again). The expansion
	 * group is specified by it's members character offsets. Also removes the given button element (that should be the
	 * button that triggered this action).
	 */
	private static async onRevertExpansionGroupClicked(
		fileIndex: number,
		codeElement: Element,
		revertExpansionButtonElement: Element,
		expandedTokenOffsets: number[]
	): Promise<void> {
		for (const offset of expandedTokenOffsets) {
			const tokenHtmlElementId = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offset);
			const tokenHtmlElement = tsdom.findElementByIdWithParent(tokenHtmlElementId, codeElement);
			if (tokenHtmlElement != null && tokenHtmlElement instanceof HTMLElement) {
				tsdom.setElementShown(tokenHtmlElement, true);
			}
		}
		tsdom.removeNodes([revertExpansionButtonElement]);
	}

	/**
	 * Finds the token elements in the given codeElement and enriches them with button elements and listeners that allow
	 * to replace them with preprocessed code and later revert the replacement again.
	 *
	 * @param codeElement The html element containing the code for the current file
	 * @param fileIndex Index of the current file (normally this is 0, but there are views showing more than one file)
	 */
	public registerPreprocessorReplacementListeners(codeElement: Element, fileIndex: number): void {
		const preprocessorExpansions = this.formatterContext.preprocessorExpansions;
		const expansionGroupToExpandedOffsets = PreprocessorExpansionHandler.mapExpansionGroupToExpandedOffsets(
			preprocessorExpansions.offsetToExpansionGroup
		);
		for (const charOffsetStr in preprocessorExpansions.offsetToExpansionGroup) {
			const charOffset = parseInt(charOffsetStr);
			const tokenHtmlElement = tsdom.findElementByIdWithParent(
				SourceFormatterContext.makeIdForFileAndOffset(fileIndex, charOffset),
				codeElement
			);
			if (tokenHtmlElement == null) {
				/* It is expected that this can be null. For example, if the code contains a sequence of keywords
				 * (e.g., "const int"), the source formatter will merge them into one "token". If an expansion group
				 * replaces both, we won't find the second one. There is potential for error here (first is not
				 * expanded, second is expanded), but this is unlikely since typically keywords are not used for
				 * macro names.
				 */
				continue;
			}
			const expansionGroup = preprocessorExpansions.offsetToExpansionGroup[charOffset]!;
			const groupOffsets = expansionGroupToExpandedOffsets.get(expansionGroup) || [charOffset];
			this.addExpansionButtonsToTokenElement(
				groupOffsets,
				expansionGroup,
				charOffset,
				fileIndex,
				codeElement,
				tokenHtmlElement
			);
		}
	}

	/**
	 * Adds mouse listeners to the given code element. In particular, we have a MOUSEOVER listener for highlighting the
	 * expansion group of this code element and a MOUSEOUT listener for removing the highlighting. In addition to these
	 * listeners, we append a new button to the last element of the expansion group. This new button is only visible
	 * during highlighting and triggers the "apply preprocessor expansion" event.
	 */
	private addExpansionButtonsToTokenElement(
		groupOffsets: number[],
		expansionGroup: number,
		charOffset: number,
		fileIndex: number,
		codeElement: Element,
		tokenHtmlElement: Element
	): void {
		const groupExpansionButtonId = PreprocessorExpansionHandler.createExpansionButtonId(expansionGroup);
		const disableGroupHighlightAction = UIUtils.preventDefaultEventAction(() =>
			PreprocessorExpansionHandler.setExpansionGroupHighlight(
				false,
				fileIndex,
				codeElement,
				groupOffsets,
				groupExpansionButtonId
			)
		);
		const enableGroupHighlightAction = UIUtils.preventDefaultEventAction(() =>
			PreprocessorExpansionHandler.setExpansionGroupHighlight(
				true,
				fileIndex,
				codeElement,
				groupOffsets,
				groupExpansionButtonId
			)
		);
		if (groupOffsets[groupOffsets.length - 1] === charOffset) {
			const expansionButton = PreprocessorExpansionHandler.createAndInsertPreprocessorExpansionButton(
				groupExpansionButtonId,
				'+',
				'apply preprocessor expansion',
				tokenHtmlElement,
				'afterend',
				true
			);

			expansionButton.classList.add(PreprocessorExpansionHandler.EXPANSION_GROUP_HIGHLIGHT_CLASS);
			tsdom.setElementShown(expansionButton, false);

			events.listen(
				expansionButton,
				EventType.CLICK,
				UIUtils.preventDefaultEventAction(() =>
					this.onApplyExpansionGroupClicked(fileIndex, codeElement, expansionGroup)
				)
			);
			events.listen(expansionButton, EventType.MOUSEOVER, enableGroupHighlightAction);
			events.listen(expansionButton, EventType.MOUSEOUT, disableGroupHighlightAction);
		}
		events.listen(tokenHtmlElement, EventType.MOUSEOVER, enableGroupHighlightAction);
		events.listen(tokenHtmlElement, EventType.MOUSEOUT, disableGroupHighlightAction);
	}

	/** Activates or deactivates the highlighting of one preprocessor-expansion group. */
	private static setExpansionGroupHighlight(
		activateHighlighting: boolean,
		fileIndex: number,
		codeElement: Element,
		groupOffsets: number[],
		expansionButtonId: string
	): void {
		for (const offset of groupOffsets) {
			const tokenHtmlElementId = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offset);
			const tokenHtmlElement = tsdom.findElementByIdWithParent(tokenHtmlElementId, codeElement);
			if (tokenHtmlElement != null && tokenHtmlElement instanceof HTMLElement) {
				tokenHtmlElement.classList.toggle(
					PreprocessorExpansionHandler.EXPANSION_GROUP_HIGHLIGHT_CLASS,
					activateHighlighting
				);
			}
		}
		const expandButtonElement = tsdom.findElementByIdWithParent(expansionButtonId, codeElement);
		if (expandButtonElement != null && expandButtonElement instanceof HTMLElement) {
			expandButtonElement.classList.toggle(
				PreprocessorExpansionHandler.EXPANSION_GROUP_HIGHLIGHT_CLASS,
				activateHighlighting
			);
			tsdom.setElementShown(expandButtonElement, activateHighlighting);
		}
	}

	/** Executes the expansion of the given expansion group. */
	private async onApplyExpansionGroupClicked(
		fileIndex: number,
		codeElement: Element,
		expansionGroupId: number
	): Promise<void> {
		const expansion = await QUERY.getSinglePreprocessorExpansion(this.project, this.uniformPath, expansionGroupId, {
			t: UnresolvedCommitDescriptor.wrap(this.formatterContext.preprocessorExpansions.commit!)
		}).fetch();
		// Remove highlighting of the expanded code (and hide the expand button again)
		PreprocessorExpansionHandler.setExpansionGroupHighlight(
			false,
			fileIndex,
			codeElement,
			expansion.offsetsOfReplacedOriginalTokens,
			PreprocessorExpansionHandler.createExpansionButtonId(expansionGroupId)
		);

		// Hide the expanded code
		for (const offset of expansion.offsetsOfReplacedOriginalTokens) {
			const tokenHtmlElementId = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offset);
			const tokenHtmlElement = tsdom.findElementByIdWithParent(tokenHtmlElementId, codeElement);
			if (tokenHtmlElement != null && tokenHtmlElement instanceof HTMLElement) {
				tsdom.setElementShown(tokenHtmlElement, false);
			}
		}
		const expansionHtmlElement = this.generatePreprocessorExpansionCodeElement(
			expansion,
			fileIndex,
			expansionGroupId
		);
		PreprocessorExpansionHandler.addRevertExpansionButton(
			expansionGroupId,
			expansionHtmlElement,
			fileIndex,
			codeElement,
			expansion
		);
		const idOfFirstExpandedElement = SourceFormatterContext.makeIdForFileAndOffset(
			fileIndex,
			expansion.offsetsOfReplacedOriginalTokens[0]!
		);
		const firstExpandedElement = tsdom.findElementByIdWithParent(idOfFirstExpandedElement, codeElement);
		if (firstExpandedElement != null) {
			firstExpandedElement.insertAdjacentElement('beforebegin', expansionHtmlElement);
		}
	}

	/**
	 * Generates a new HTML element that contains the result of the preprocessor expansion. All element ids in this
	 * element are prefixed with the expansion-group id (so they won't clash with other elements).
	 */
	private generatePreprocessorExpansionCodeElement(
		expansion: SinglePreprocessorExpansionTransport,
		fileIndex: number,
		expansionGroup: number
	): HTMLElement {
		const styleRegions = SourceFormatterUtils.buildStyleRegions(
			expansion.tokensOffsetsAndStyles,
			expansion.styles,
			this.tokenStyleCssCache,
			[]
		);
		let replacementText = expansion.replacementText;
		if (replacementText.length === 0) {
			replacementText = ' ';
		}
		const expansionHtmlElement = document.createElement('span');
		const renderedReplacementText = UIUtils.sanitizedHtml(
			this.renderReplacementText(styleRegions, replacementText, fileIndex)
		);
		expansionHtmlElement.innerHTML = renderedReplacementText.getContent();
		expansionHtmlElement.classList.add(PreprocessorExpansionHandler.EXPANSION_GROUP_EXPANDED_CLASS);
		tsdom.setElementShown(expansionHtmlElement, true);

		/* Fix ids of the generated elements (the ids will contain offsets starting at 0 and might clash with ids from
		 * the original code)
		 */
		for (const styleRegion of styleRegions) {
			const offset = styleRegion.startOffset;
			const tokenHtmlElementId = SourceFormatterContext.makeIdForFileAndOffset(fileIndex, offset);
			const tokenHtmlElement = tsdom.findElementByIdWithParent(tokenHtmlElementId, expansionHtmlElement);
			if (tokenHtmlElement != null) {
				tokenHtmlElement.id = 'expansion-' + expansionGroup + '-' + tokenHtmlElementId;
			}
		}
		return expansionHtmlElement;
	}

	/** Renders the given replacement text for a preprocessor expansion. */
	private renderReplacementText(styleRegions: StyleRegion[], replacementText: string, fileIndex: number): string {
		const localFormatterContext = new SourceFormatterContext(styleRegions, null, this.project);
		const formattedLine = SourceLineFormatter.formatLine(
			localFormatterContext,
			replacementText,
			0,
			false,
			fileIndex
		);
		return formattedLine;
	}
}
