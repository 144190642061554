import { linkTo } from 'ts/commons/links/LinkTo';
import { ERepositoryPerspectiveView } from 'ts/perspectives/repository/ERepositoryPerspectiveView';
import type { ERepositorySetupStep } from 'ts/perspectives/repository/ERepositorySetupStep';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL to the Repository Overview view. */
export function repositoryOverview() {
	return linkTo(ETeamscalePerspective.REPOSITORIES, ERepositoryPerspectiveView.OVERVIEW);
}

/**
 * Returns a URL to the Repository Setup view.
 *
 * @param params
 * @param params.step Step within the setup view.
 */
export function repositorySetup(params: { step: ERepositorySetupStep }) {
	return linkTo(ETeamscalePerspective.REPOSITORIES, ERepositoryPerspectiveView.SETUP, params);
}

/** Returns a URL for editing a repository. */
export function editRepository(project: string) {
	return linkTo(ETeamscalePerspective.REPOSITORIES, ERepositoryPerspectiveView.EDIT, {
		name: project
	});
}
