/// <reference types="highlight.js/types/index.d.ts" />
import DOMPurify from 'dompurify';
import hljs from 'highlight.js/lib/core';
import c from 'highlight.js/lib/languages/c';
import cpp from 'highlight.js/lib/languages/cpp';
import csharp from 'highlight.js/lib/languages/csharp';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import markdown from 'highlight.js/lib/languages/markdown';
import python from 'highlight.js/lib/languages/python';
import typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/styles/xcode.css';
import { marked, type MarkedOptions, Renderer, type Tokens } from 'marked';
import { markedHighlight } from 'marked-highlight';
import markedLinkifyIt from 'marked-linkify-it';
import * as UIUtilsTemplate from 'soy/commons/UIUtilsTemplate.soy.generated';
import type { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import type { HtmlSanitizer } from 'ts-closure-library/lib/html/sanitizer/htmlsanitizer';
import { Builder } from 'ts-closure-library/lib/html/sanitizer/htmlsanitizer';
import * as unsafe from 'ts-closure-library/lib/html/sanitizer/unsafe';
import { Const } from 'ts-closure-library/lib/string/const';
import * as style from 'ts-closure-library/lib/style/style';
import { AdvancedTooltip } from 'ts-closure-library/lib/ui/advancedtooltip';
import * as soy from 'ts/base/soy/SoyRenderer';
import { HeaderReducingRenderer } from 'ts/commons/markdown/HeaderReducingRenderer';
import { StringUtils } from 'ts/commons/StringUtils';

marked.use(markedLinkifyIt());

/** Adds syntax highlight in the rendered code blocks (the ``` markup annotation) using the HighlightJS library. */
marked.use(
	markedHighlight({
		langPrefix: 'hljs language-',

		/** @param language If not given, a language-independent auto-highlighting will be performed */
		highlight(input: string, language?: string | null): string {
			if (language != null && hljs.getLanguage(language)) {
				try {
					return hljs.highlight(input, { language }).value;
				} catch (err) {
					console.error(err);
				}
			}
			try {
				return hljs.highlightAuto(input).value;
			} catch (err) {
				console.error(err);
			}
			// Use external default escaping
			return '';
		}
	})
);

marked.use({
	useNewRenderer: true,
	renderer: {
		table(token: Tokens.Table): string {
			const tableString = Renderer.prototype.table.apply(this, [token]);
			return '<table class="ui markdown table">' + StringUtils.stripPrefix(tableString, '<table>');
		}
	}
});

hljs.registerLanguage('c', c);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('python', python);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('markdown', markdown);
hljs.registerLanguage('java', java);

// Make all links rendered via Markdown safely open in a new tab
DOMPurify.addHook('afterSanitizeAttributes', node => {
	if ('target' in node) {
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener noreferrer');
	}
});

/** Wrapper methods for the marked library (markup rendering). https://github.com/markedjs/marked */
export class MarkdownUtils {
	/** Renders the markdown with headings being de-emphasized by reducing their level by 2 i.e. <h1> becomes <h3>. */
	public static readonly REDUCE_HEADER_LEVELS: MarkedOptions = {
		renderer: new HeaderReducingRenderer()
	};

	/** Markup code for the syntax-help tooltip. */
	public static readonly MARKDOWN_SYNTAX_FOR_TOOLTIP =
		'|     Input     | Rendered           |\n' +
		'| :--: | --: |\n' +
		'| \\*\\*Bold\\*\\* or    \\_\\_Bold\\_\\_ \t|   **Bold**    |\n' +
		'| \\*Italic\\* or \\_Italic\\_ \t|   *Italic*    |\n' +
		'| \\~\\~strikethrough\\~\\~ | ~~strikethrough~~ |\n' +
		'| \\[Link\\]\\(http://www.teamscale.com\\) | [Link](http://www.teamscale.com) |\n' +
		'|  (URLs are linked automatically)       | http://teamscale.com  |\n' +
		'| \\!\\[Image\\]\\(http://... .png\\) | ![Image](images/teamscale-logo.svg) |\n' +
		'| \\`inline code\\` (backticks) | `inline code` (backticks)|\n' +
		'|\\( c \\)  \\( TM \\)  \\( R \\)  \\+ \\-  \\( p \\)|(c) (TM) (R) +- (p)|\n' +
		'\n' +
		'* List item (put \\* or \\- before list items)\n' +
		'\n' +
		'1. Numbered list item (put `1.`, `2.`,... (or `1)`,`2)`,...) before list items)\n' +
		'\n' +
		'Horizontal Rule (start line with \\*** or \\-\\-\\-)\n' +
		'***\n' +
		'> Blockquote (start line with \\>)\n' +
		'\n' +
		'# Heading 1 (start line with \\#)\n' +
		'## Heading 2 (start line with \\#\\#)\n' +
		'\n' +
		'We support most syntax elements of [commonmark](http://commonmark.org/help/) and [GitHub Flavored Markdown](https://help.github.com/articles/about-writing-and-formatting-on-github/).\n' +
		'\n' +
		"We use the rendering engine '**marked**' [(Github)](https://github.com/markedjs/marked).";

	/**
	 * Sanitizer that allows (a whitelist of) formatting tags (which might be generated by marked or might be contained
	 * in html mixed in the markup text). This sanitizer removes "dangerous" tags such as <script>. We allow class names
	 * as they are needed by our code syntax highlighter (Highlight JS)
	 */
	private static readonly SANITIZER: HtmlSanitizer = unsafe
		.alsoAllowAttributes(
			Const.from("Allow the src attribute of img tags (otherwise, images won't work"),
			new Builder(),
			[
				{
					tagName: 'img',
					attributeName: 'src',
					policy: null
				},
				{ tagName: '*', attributeName: 'class', policy: null }
			]
		)
		.build();

	/**
	 * Wrapper for the marked.parse method. Allows to override the default rendering options and removes unnecessary<p>
	 * tags added by marked.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML.
	 */
	private static renderWithMarked(markupString: string | null, options: MarkedOptions = {}, inline = false): string {
		if (markupString == null) {
			return '';
		}
		let renderedText;
		if (inline) {
			renderedText = marked.parseInline(markupString, options) as string;
		} else {
			renderedText = marked.parse(markupString, options) as string;
		}

		renderedText = renderedText.trim();
		return renderedText;
	}

	/**
	 * Takes markdown and returns a sanitized HTML string. An HTML sanitizer removes any non-formatting tags. The
	 * dompurify package is used for sanitization, so the output is a string that can be dropped into the DOM without
	 * risking XSS attacks.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderToHtml(markupString: string | null, options?: MarkedOptions, inline = false): string {
		const rendered = MarkdownUtils.renderWithMarked(markupString, options, inline);
		return DOMPurify.sanitize(rendered, { ADD_TAGS: ['sub', 'sup', 'span'] });
	}

	/**
	 * Takes markdown and returns a sanitized string without any HTML tags so that the result can be used e.g. in the
	 * "title" attribute. HTML input is allowed to pass the markup rendering. Afterwards an HTML sanitizer removes any
	 * tags.
	 *
	 * @param markupString To be rendered
	 * @returns Rendered text
	 */
	public static renderToPlainText(markupString: string | null, options?: MarkedOptions): string {
		const rendered = this.htmlDecode(MarkdownUtils.renderWithMarked(markupString, options));
		return DOMPurify.sanitize(rendered, { ALLOWED_TAGS: [] });
	}

	private static htmlDecode(input: string | null): string {
		if (input === null) {
			return '';
		}
		const doc = new DOMParser().parseFromString(input, 'text/html');
		return doc.documentElement.textContent ?? '';
	}

	/**
	 * Takes markdown and returns a sanitized SafeHtml instance that can be passed into a soy template. HTML input is
	 * allowed to pass the markup rendering. Afterwards an HTML sanitizer removes any tags.
	 *
	 * @deprecated For new code use the <Markdown /> React component
	 * @param markupString To be rendered
	 * @returns Rendered HTML
	 */
	public static renderToSafeHtml(markupString: string | null, options?: MarkedOptions, inline = false): SafeHtml {
		const rendered = MarkdownUtils.renderToHtml(markupString, options, inline);
		return MarkdownUtils.SANITIZER.sanitize(rendered);
	}

	/**
	 * Attaches a help tooltip with the markup formatting syntax help to the given element. For elements migrated to
	 * React {@link HelpIcon} can be used.
	 *
	 * @param element Element to become the tooltip.
	 */
	public static attachSyntaxAdvancedTooltip(element: Element | null): void {
		if (!element) {
			return;
		}
		const tooltip = new AdvancedTooltip();
		const renderedSyntaxHelp = MarkdownUtils.renderToSafeHtml(MarkdownUtils.MARKDOWN_SYNTAX_FOR_TOOLTIP);
		const tooltipInsideElement = soy.renderAsElement(UIUtilsTemplate.divWithIdWrapper, {
			id: 'syntaxTooltip',
			content: renderedSyntaxHelp
		});
		tooltip.setElement(tooltipInsideElement);
		style.setStyle(tooltip.getElement(), { 'z-index': '10001', position: 'absolute', display: 'none' });
		tooltip.attach(element);
	}
}
