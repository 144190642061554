import { QUERY } from 'api/Query';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import {
	DashboardPerspectiveSettingsBarAddition,
	StandaloneDashboardAddDropdownButton
} from 'ts/perspectives/dashboard/DashboardPerspectiveSettingsBarAddition';
import { DashboardResolver } from 'ts/perspectives/dashboard/DashboardResolver';
import { WidgetUtils } from 'ts/perspectives/dashboard/widgets/WidgetUtils';
import { EBasicPermission } from 'typedefs/EBasicPermission';
import type { LastDashboardOpenedByUserOption } from 'typedefs/LastDashboardOpenedByUserOption';

async function getProjects(
	projectId: string | null,
	hash: NavigationHash,
	option: LastDashboardOpenedByUserOption,
	signal: AbortSignal
): Promise<string[]> {
	try {
		const dashboards = await QUERY.getAllDashboards({ project: projectId ?? undefined }).fetch({
			signal
		});
		const dashboardId = new DashboardResolver(projectId, hash, option).determineDashboardIdToShow(dashboards);
		if (signal.aborted) {
			return [];
		}
		ensureIdMatchesHash(projectId, dashboardId, hash);
		if (dashboardId === null) {
			return [];
		}
		const dashboardDescriptor = await QUERY.getDashboard(dashboardId).fetch({ signal });
		return WidgetUtils.getProjects(dashboardDescriptor);
	} catch {
		return [];
	}
}

function ensureIdMatchesHash(projectId: string | null, dashboardId: string | null, hash: NavigationHash) {
	if (hash.getId() !== dashboardId) {
		if (dashboardId === null) {
			hash.remove(NavigationHash.ID_PARAMETER);
		} else {
			hash.setId(dashboardId);
		}
		hash.setProjectAndPath(ProjectAndUniformPath.of(projectId, null));
		hash.navigate(true);
	}
}

/** Defines all sub-views of the dashboard perspective */
export const EDashboardPerspectiveView = {
	DASHBOARD_SHOW: {
		name: '',
		anchor: 'show',
		hasSelfManagedTitle: true,
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./show/ShowDashboardView'),
		additionalPerspectiveSettingsComponent: () => (
			<DashboardPerspectiveSettingsBarAddition showEditMenu requirePermission={EBasicPermission.VIEW} />
		),
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_NEW: {
		name: 'New dashboard',
		anchor: 'new',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./sidebar/DashboardSidebarCreateView'),
		additionalPerspectiveSettingsComponent: () => <StandaloneDashboardAddDropdownButton />,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_EDIT: {
		name: 'Edit dashboard',
		anchor: 'edit',
		requiresProject: true,
		hasSelfManagedTitle: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./sidebar/DashboardSidebarEditView'),
		additionalPerspectiveSettingsComponent: () => (
			<DashboardPerspectiveSettingsBarAddition requirePermission={EBasicPermission.EDIT} />
		),
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	TEMPLATES: {
		name: '',
		anchor: 'templates',
		requiresProject: false,
		showAllProjects: true,
		view: () => import('./templates/DashboardTemplatesView'),
		additionalPerspectiveSettingsComponent: () => <StandaloneDashboardAddDropdownButton />
	} as ViewDescriptor,
	SHARING: {
		name: '',
		anchor: 'sharing',
		requiresProject: true,
		showAllProjects: true,
		hasCustomAnalysisWarning: true,
		viewCreator: () => import('./DashboardSharingView'),
		additionalPerspectiveSettingsComponent: () => (
			<DashboardPerspectiveSettingsBarAddition showEditMenu requirePermission={EBasicPermission.EDIT_ROLES} />
		)
	} as ViewDescriptor
};
