import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

/** Defines all sub-views of the repository perspective */
export const ERepositoryPerspectiveView = {
	/** Shows a list of all configured projects similar to the project overview for non-Teamscale.io instances. */
	OVERVIEW: {
		name: 'Repositories',
		anchor: 'overview',
		requiresProject: false,
		view: () => import('./RepositoryListView')
	} as ViewDescriptor,
	/** Shows a wizard to create projects from existing repositories. */
	SETUP: {
		name: 'Setup repository',
		anchor: 'setup',
		requiresProject: false,
		viewCreator: () => import('./RepositorySetupView')
	} as ViewDescriptor,
	/** Allows to edit the settings of an already configured project. */
	EDIT: {
		name: 'Edit repository settings',
		anchor: 'edit',
		requiresProject: false,
		viewCreator: () => import('./RepositoryEditSettingsView')
	} as ViewDescriptor
};
