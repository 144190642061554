import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';

export const EAdminPerspectiveView = {
	SETTINGS: {
		name: 'Settings',
		anchor: 'options',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./AdminOptionsView'),
		canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_SERVER_OPTIONS);
		}
	} as ViewDescriptor,
	USERS: {
		name: 'Users',
		anchor: 'users',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./users/AdminUserView'),
		canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_USERS);
		}
	} as ViewDescriptor,
	GROUPS: {
		name: 'Groups',
		anchor: 'groups',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./groups/AdminGroupView'),
		canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_GROUPS);
		}
	} as ViewDescriptor,
	ROLES: {
		name: 'Roles',
		anchor: 'roles',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./roles/AdminRoleView'),
		canBeAccessed(context: PerspectiveContext) {
			return (
				PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_ROLES) ||
				PermissionUtils.hasGlobalPermission(context, EGlobalPermission.EDIT_GLOBAL_ROLES)
			);
		}
	},
	INSTANCE_COMPARISON: {
		name: 'Instance Comparison',
		anchor: 'instance-compare',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./instance-comparison/overview/InstanceComparisonView'),
		canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor,
	EXTERNAL_ACCOUNTS: {
		name: 'External Accounts',
		anchor: 'external-accounts',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./external-accounts/ExternalAccountsView'),
		canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS);
		}
	} as ViewDescriptor,
	EXTERNAL_ACCOUNT_ROLES: {
		name: 'External Accounts',
		anchor: 'external-accounts',
		action: 'roles',
		requiresProject: false,
		view: () => import('./external-accounts/ExternalAccountsRolesView'),
		canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS);
		}
	} as ViewDescriptor,
	EXTERNAL_STORAGE_BACKENDS: {
		name: 'Storage Backends',
		anchor: 'external-storage-backends',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./external-storage-backends/ExternalStorageBackendsView'),
		canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS);
		}
	} as ViewDescriptor,
	EXTERNAL_STORAGE_BACKEND_ROLES: {
		name: 'Storage Backends',
		anchor: 'external-storage-backends',
		action: 'roles',
		requiresProject: false,
		view: () => import('./external-storage-backends/ExternalStorageBackendRolesView'),
		canBeAccessed(context) {
			return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_EXTERNAL_CREDENTIALS);
		}
	} as ViewDescriptor,
	BACKUP: {
		name: 'Backup',
		anchor: 'backup',
		requiresProject: false,
		visibleInSidebar: true,
		viewFactory: () => import('./backup/AdminBackupView'),
		canBeAccessed(context) {
			return (
				PermissionUtils.hasGlobalPermission(context, EGlobalPermission.BACKUP) ||
				PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_PROJECTS)
			);
		}
	} as ViewDescriptor,
	NOTIFICATIONS: {
		name: 'Notifications',
		anchor: 'notifications',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./admin_notification_view/AdminNotificationsView'),
		canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor,
	EVENT_ANNOUNCEMENT: {
		name: 'Event Announcement',
		anchor: 'event-announcement',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./event-announcement/EventAnnouncementView'),
		canBeAccessed(context) {
			return PermissionUtils.hasGlobalPermission(context, EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES);
		}
	} as ViewDescriptor
} as const;
