import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import { ERepositoryPerspectiveView } from 'ts/perspectives/repository/ERepositoryPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the repository perspective. */
export class RepositoryPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.REPOSITORIES, ERepositoryPerspectiveView);
	}
}
