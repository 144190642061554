import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { LegacyRightSidebar } from 'ts/base/perspective/sidebar/right/LegacyRightSidebar';
import { THRESHOLD_TO_MINIMIZE_SIDEBARS } from 'ts/base/perspective/sidebar/SidebarToggleHook';
import type { DisposableKeyboardShortcutRegistry } from 'ts/base/scaffolding/DisposableKeyboardShortcutRegistry';
import { ReactDisposable } from 'ts/base/view/ReactDisposable';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { Assertions } from 'ts/commons/Assertions';
import { ErrorFallback } from 'ts/commons/ErrorFallback';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import { Segment } from 'ts/components/Segment';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { EGlobalPermission } from 'typedefs/EGlobalPermission';
import { EProjectPermission } from 'typedefs/EProjectPermission';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';

/**
 * Base class for Teamscale views. A view encapsulates the visual state of a perspective, i.e. a perspective can have
 * multiple views. Only one view is displayed at a time, depending on user input or the data to be displayed.
 *
 * The life-cycle of a view is <ul> <li>init: perform deferred initialization</li> <li>preloadContentAsync: fetch data
 * that should be available at rendering time</li> <li>isRightSidebarVisible: de/activate
 * sidebar</li><li>appendRightSidebarContent: fill in sidebar if required</li> <li>appendMainContent: fill in main page
 * content</li><li>dispose: before the view is removed, this is called to allow clean up</li> </ul>
 */
export abstract class TeamscaleViewBase extends ReactDisposable {
	/** Separator used for the page title. */
	public static TITLE_SEPARATOR = ' \u00b7 ';

	/** Will be non-null after {@link #init()} was called. */
	protected perspectiveContext: ExtendedPerspectiveContext | null = null;

	/** The default branch name. */
	protected defaultBranchName: string | null = null;

	/** Registry for keyboard shortcuts. May remain null even after initialization. */
	protected shortcutRegistry: DisposableKeyboardShortcutRegistry | null = null;

	/** The right sidebar. */
	public rightSidebar?: LegacyRightSidebar;

	/** The view descriptor. */
	public viewDescriptor: ViewDescriptor | null = null;

	/** The abort controller for the view which will be aborted when the view is disposed. */
	protected abortController = new AbortController();

	/** Remembers whether the current view has already been disposed. */
	public get disposed() {
		return this.abortController.signal.aborted;
	}

	/** Performs additional initialization steps after construction. */
	public init(
		perspectiveContext: ExtendedPerspectiveContext,
		defaultBranchName: string | null,
		shortcutRegistry: DisposableKeyboardShortcutRegistry
	): void {
		this.perspectiveContext = perspectiveContext;
		this.shortcutRegistry = shortcutRegistry;
		this.defaultBranchName = defaultBranchName;
	}

	public override getPerspectiveContext(): ExtendedPerspectiveContext {
		return Assertions.assertObject(this.perspectiveContext);
	}

	/**
	 * Allows to load data asynchronously. The append methods for creating page content are called only after the data
	 * is available.
	 */
	public async preloadContentAsync(): Promise<void> {
		// Empty
	}

	/** Allows set a view specific suffix for the browser title. */
	public getViewTitle(): string | null {
		return this.viewDescriptor?.name ?? null;
	}

	/**
	 * Template method for showing/hiding the right sidebar.
	 *
	 * @returns <code>true</code> iff the sidebar should be shown.
	 */
	protected isRightSidebarVisible(): boolean {
		return false;
	}

	/** Template method for adding the sidebar content of the view. */
	protected appendRightSidebarContent(container: HTMLElement): void {
		this.appendComponent(<Segment color="red">Not implemented yet!</Segment>, container);
	}

	/** Renders the view into the given container element including the surrounding padding layout. */
	public renderInto(
		element: HTMLElement,
		viewContent: HTMLElement,
		activePerspective: ETeamscalePerspective,
		hash: NavigationHash
	): void {
		if (this.disposed) {
			return;
		}
		this.setTitle(activePerspective);
		const viewId = activePerspective.name + '-' + hash.getViewName();
		this.appendRightSidebar(element, viewId);
		this.appendMainContent(viewContent);
	}

	/** Sets the browser title. */
	protected setTitle(activePerspective: ETeamscalePerspective): void {
		if (this.viewDescriptor?.hasSelfManagedTitle) {
			return;
		}
		document.title = activePerspective.displayName;
		const viewTitle = this.getViewTitle();
		if (!StringUtils.isEmptyOrWhitespace(viewTitle)) {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + viewTitle;
		}
		const project = NavigationHash.getProject();
		if (!StringUtils.isEmptyOrWhitespace(project)) {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + project;
		}
		document.title += TeamscaleViewBase.TITLE_SEPARATOR + 'Teamscale';
	}

	private appendRightSidebar(container: Element, viewId: string): void {
		const element = this.renderRightSidebar();
		if (element != null) {
			this.appendComponent(element, container);
			return;
		}
		if (this.isRightSidebarVisible()) {
			this.rightSidebar = new LegacyRightSidebar(container, viewId);
			const renderRightSidebarCollapsed =
				dom.getViewportSize().width < THRESHOLD_TO_MINIMIZE_SIDEBARS || this.rightSidebar.isCollapsedByUser();
			const rightSidebar = tsdom.getElementById('right-sidebar');
			this.appendRightSidebarContent(rightSidebar);

			if (renderRightSidebarCollapsed && !this.rightSidebar.isCollapsed()) {
				this.rightSidebar.toggle(true);
			}
		}
	}

	/**
	 * Renders the right sidebar in case it is completely implemented in React. When the method returns a non-null value
	 * #isRightSidebarVisible and #appendRightSidebarContent are ignored.
	 */
	protected renderRightSidebar(): JSX.Element | null {
		return null;
	}

	/**
	 * Template method for adding the main content of the view.
	 *
	 * @param element The element to add the content to.
	 */
	protected abstract appendMainContent(element: HTMLElement): void;

	/**
	 * Returns an error handler that will render an error into the given element once the function is called with a JS
	 * error.
	 */
	protected getErrorHandler(element: Element): (error: unknown) => void {
		return error =>
			this.appendComponent(
				<ErrorFallback error={error} resetErrorBoundary={() => window.location.reload()} />,
				element
			);
	}

	/** Allows to clean up resources or unregister listeners when the view is no longer needed. */
	public override dispose(): void {
		this.abortController.abort();
		this.rightSidebar?.dispose();
		this.shortcutRegistry?.dispose();
		super.dispose();
	}

	/** Loads the default branch name for the given project. */
	protected async loadDefaultBranchName(project: string): Promise<void> {
		this.defaultBranchName = await QUERY.getFirstUIBranchNameForProject(project).fetch();
	}

	/** Returns true if the current user has permission to edit tasks for the current project. */
	protected mayEditTasks(project: string): boolean {
		return this.hasProjectPermission(project, EProjectPermission.EDIT_TASKS);
	}

	/**
	 * Returns true if the current user has permission to edit the findings blacklist for findings with a specific
	 * assessment type in the current project.
	 *
	 * @param project
	 * @param assessment Type of the finding
	 */
	protected mayEditBlacklistForAssessmentType(project: string, assessment: string): boolean {
		switch (assessment) {
			case ETrafficLightColor.YELLOW.name:
				return this.hasProjectPermission(project, EProjectPermission.EXCLUDE_YELLOW_FINDINGS);
			case ETrafficLightColor.RED.name:
				return this.hasProjectPermission(project, EProjectPermission.EXCLUDE_RED_FINDINGS);
			default:
				return false;
		}
	}

	/** Returns true if the current user has permission to edit architectures for the project. */
	protected mayEditArchitectures(project: string): boolean {
		return this.hasProjectPermission(project, EProjectPermission.EDIT_ARCHITECTURES);
	}

	/** Returns true if the current user has the given global permission. */
	protected hasGlobalPermission(globalPermission: EGlobalPermission): boolean {
		return PermissionUtils.hasGlobalPermission(this.perspectiveContext!, globalPermission);
	}

	/** Returns true if the current user has the given project permission for the project or project alias. */
	protected hasProjectPermission(project: string, projectPermission: EProjectPermission): boolean {
		return PermissionUtils.hasProjectPermission(this.perspectiveContext, project, projectPermission);
	}

	public setViewDescriptor(viewDescriptor: ViewDescriptor): void {
		this.viewDescriptor = viewDescriptor;
	}
}
