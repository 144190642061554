/** The steps of the repository setup wizard. */
export enum ERepositorySetupStep {
	INSTALL_APP = 1,
	REPOSITORY_SELECTION,
	ADJUST_CONFIG,
	ANALYSIS_PROGRESS
}

/** The first step of the wizard. */
export const FIRST_STEP = ERepositorySetupStep.INSTALL_APP;

/** The last step of the wizard. */
export const LAST_STEP = ERepositorySetupStep.ANALYSIS_PROGRESS;
